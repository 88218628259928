.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    canvas {
        max-width: 46rem;
        max-height: 35rem;
    }
}
