.container {
    margin-top: 0.5rem;
    height: fit-content;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    border-radius: 1rem;
    padding: 0.5rem;
    margin-left: 2rem;
    @media (max-width: 1000px) {
        margin-left: 0;
    }

    @media (min-width: 1650px) {
        width: 30%;
    }

    table {
        width: 100%;
        border-collapse: collapse;

        td {
            text-align: center;
            padding: 1rem;
            // width: 100%;
            white-space: nowrap;
        }

        th {
            text-align: left;
            padding: 0.5rem;
            text-transform: uppercase;
        }

        thead th {
            color: #4f5050;
        }

        tbody {
            tr:nth-child(odd) {
                background: rgba(0, 0, 0, 0.03);
            }

            .alternatives {
                // display: flex;
                // align-items: center;
                // justify-content: center;
                // width: 100%;
                // border: 1px solid red;

                select {
                    width: 100%;
                    text-align: center;
                }
            }

            .lock {
                display: flex;
                align-items: center;
                justify-content: center;
                // width: 100%;
            }

            p.bubble {
                background-color: red;
                border-radius: 25px;
                margin: 0;
                width: 1.5rem;
                height: 1.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
            }
        }
    }
}
