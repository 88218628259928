@import '../../theming//theme.module.scss';

.container {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    border-radius: 1rem;
    background-color: white;
    position: relative;
    padding: 2rem;
    display: flex;

    & .inputs {
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    & .actions {
        display: flex;
        flex-direction: column;
        padding-left: 2rem;
        width: 50%;

        .action {
            padding-bottom: 1rem;
        }

        & .button {
            cursor: pointer;
            background-color: $primaryColor;
            width: 100%;
            border-radius: 3px;
            display: flex;
            justify-content: center;

            &:hover {
                background-color: darken($color: $primaryColor, $amount: 10);
            }

            &:active {
                background-color: darken($color: $primaryColor, $amount: 20);
            }

            p.buttonTitle {
                color: white;
                margin: 0.5rem 0;
                font-weight: bold;
                letter-spacing: normal;
                font-size: 1rem;
                text-align: center;
                font-family: 'Roboto';
            }
        }
    }

    & .inputContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: 1rem;

        &.inputContainerRelaxed {
            margin: 0;
            padding: 0;
            padding-bottom: 1rem;
        }

        & .label {
            color: black;
            font-family: 'Roboto';
            font-size: 0.9rem;
            padding-bottom: 0.25rem;

            &.required:after {
                content: '*';
                color: red;
            }
        }

        & .textarea {
            resize: none;
            font-size: 1rem;
            font-family: 'Roboto';
            padding: 0.875rem 1rem;
            border: 1px solid #ccc;
            border-radius: 4px;
            &:focus {
                outline-width: 0;
            }
        }

        & .input {
            font-size: 1rem;
            font-family: 'Roboto';
            padding: 0.875rem 1rem;
            border: 1px solid #ccc;
            border-radius: 4px;

            &:focus {
                outline-width: 0;
            }
        }

        & .select {
            font-size: 1rem;
            font-family: 'Roboto';
            padding: 0.875rem 1rem;
            border: 1px solid #ccc;
            border-radius: 4px;
            padding-right: 2rem !important;
            background-position: calc(100% - 1rem) center !important;
            -moz-appearance: none !important;
            -webkit-appearance: none !important;
            appearance: none !important;
            background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
                no-repeat;

            &:focus {
                outline-width: 0;
            }
        }

        & .inputError {
            margin: 0;
            position: absolute;
            bottom: 0;
            color: red;
            font-size: 0.75rem;
        }
    }

    & .success {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .check {
            padding-right: 2rem;
            width: 3rem;
        }

        & p {
            margin: 0;
        }
    }

    & .error {
        width: 100%;
        height: 100%;
        display: flex;

        .warning {
            padding-right: 2rem;
            width: 3rem;
        }

        .errorsContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        & p {
            margin: 0;
            &:nth-child(2) {
                margin-top: 1rem;
            }
        }
    }
}
