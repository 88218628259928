@import '../../theming//theme.module.scss';

.container {
    position: relative;
    flex-direction: column;
    display: flex;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10%;

    @media (max-width: 1500px) {
        padding: 0 5%;
    }

    @media (max-width: 1000px) {
        padding: 0 5rem;
    }

    .innerContainer {
        display: flex;
        flex-direction: column;
        width: 100%;

        .title {
            margin: 0;
            font-size: 1.5rem;
            color: black;
            font-family: 'Roboto';
            font-weight: 700;
            line-height: normal;
            letter-spacing: normal;
        }

        .subtitle {
            margin: 1rem 0;
            font-size: 1rem;
            color: black;
            font-family: 'Roboto';
        }

        .outerContainer {
            display: flex;
            max-width: 100%;
            justify-content: center;
            width: 100%;
            position: relative;

            &.success {
                justify-content: center;
            }

            @media (max-width: 1600px) {
                flex-direction: column;
                align-items: center;
            }

            .carouselContainer {
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
                border-radius: 1rem;
                display: flex;
                background-color: white;
                margin-top: 0.5rem;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                position: relative;
                width: 70%;
                margin-bottom: 2rem;

                @media (max-width: 1650px) {
                    width: 80%;
                }

                @media (max-width: 1450px) {
                    width: 100%;
                }

                .check {
                    width: 7rem;
                    z-index: 1;
                    position: absolute;
                }

                * {
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                .carousel {
                    max-width: 100%;

                    .imageContainer {
                        // padding: 5%;
                        height: 100%;
                        width: 100%;
                        justify-content: center;
                        align-items: center;
                        display: flex;

                        & .image {
                            // max-height: 40rem;
                            max-width: 25rem;
                        }
                    }
                }
            }
        }
    }

    .nextContainer {
        margin-top: 2rem;
        // width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 16rem;

        @media (max-width: 1400px) {
            padding: 0 7rem;
        }

        @media (max-width: 1000px) {
            padding: 0;
        }

        .next {
            display: flex;
            margin: 0;
            margin-bottom: 1rem;
            font-size: 2rem;
            font-weight: bold;
            color: black;
            text-align: left;

            @media (max-width: 1000px) {
                width: 70%;
            }
        }

        .nextInfo {
            display: flex;
            margin: 0;
            margin-bottom: 1rem;
            font-size: 1rem;
            color: black;
            text-align: left;

            @media (max-width: 1000px) {
                width: 70%;
            }
        }
    }
}
