* {
    font-family: 'Roboto', sans-serif;
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}
