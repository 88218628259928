@import '../../theming//theme.module.scss';

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 30%;

    @media (max-width: 1500px) {
        margin: 0 20%;
    }

    @media (max-width: 1000px) {
        margin: 0 5rem;
    }

    .title {
        margin: 0;
        font-size: 1.5rem;
        color: black;
        font-family: 'Roboto';
        font-weight: 700;
        line-height: normal;
        letter-spacing: normal;
    }

    .uploadTitle {
        color: black;
        font-size: 1.5em;
        font-weight: bold;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    .subtitle {
        margin: 1rem 0;
        font-size: 1rem;
        color: black;
        font-family: 'Roboto';
    }

    hr {
        background-color: lightgray;
        height: 3px;
        width: 100%;
        border: 0;
    }

    .uploadBox {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
        border-radius: 1rem;
        display: flex;
        margin-top: 0.5rem;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        * {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .uploadActions {
            margin-top: 4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .uploadTitle {
            color: black;
            font-size: 1.5em;
            font-weight: bold;
            margin-top: 2rem;
            margin-bottom: 1rem;
        }

        .uploadImage {
            width: 8rem;
        }

        .dnd {
            font-size: 1.75rem;
            color: black;
            margin: 0;
        }

        .or {
            color: gray;
            font-size: 1.5rem;
            margin: 0;
            margin: 2rem 0;
        }

        .button {
            background-color: $primaryColor;
            box-shadow: 2px 2px 4px -2px #000000;
            border-radius: 3px;
            padding: 0rem 2rem;

            &:hover {
                background-color: darken($color: $primaryColor, $amount: 10);
            }

            &:active {
                background-color: darken($color: $primaryColor, $amount: 20);
            }

            .buttonTitle {
                color: white;
                font-weight: bold;
                letter-spacing: normal;
                font-size: 1rem;
                font-family: 'Roboto';
            }
        }

        .error {
            margin: 0;
            color: red;
            font-size: 1rem;
        }

        .dataFormats {
            color: black;
            font-size: 0.875rem;
            font-style: italic;
        }

        .firstPageOnly {
            // padding: 0 1rem;
            margin: 2rem;
            color: black;
            font-size: 0.875rem;
        }
    }

    .uploadHover {
        border: 5px dashed $primaryColor;
    }
}
