@import '../../theming//theme.module.scss';

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 20%;
    @media (max-width: 1000px) {
        margin: 0 5%;
    }

    .title {
        margin: 0;
        font-size: 1.5rem;
        color: black;
        font-family: 'Roboto';
        font-weight: 700;
        line-height: normal;
        letter-spacing: normal;
    }

    .subtitle {
        margin: 1rem 0;
        font-size: 1rem;
        color: black;
        font-family: 'Roboto';
    }

    .carouselContainer {
        border: 2px solid darken($color: lightgrey, $amount: 5);
        display: flex;
        background-color: #efefef;
        margin-top: 0.5rem;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        position: relative;

        * {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .imageContainer {
            padding: 5%;
            height: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            .image {
                width: 25rem;
            }
        }
        .check {
            width: 7rem;
            z-index: 1;
            position: absolute;
        }
    }

    .nextContainer {
        margin-top: 2rem;
        width: 100%;
        display: flex;
        flex-direction: column;

        .next {
            display: flex;
            margin: 0;
            margin-bottom: 1rem;
            font-size: 2rem;
            font-weight: bold;
            color: black;
            text-align: left;

            @media (max-width: 1000px) {
                width: 70%;
            }
        }

        .nextInfo {
            display: flex;
            margin: 0;
            margin-bottom: 1rem;
            font-size: 1rem;
            color: black;
            text-align: left;

            @media (max-width: 1000px) {
                width: 70%;
            }
        }
    }
}
