@import '../../theming//theme.module.scss';

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 30%;

    @media (max-width: 1500px) {
        margin: 0 20%;
    }

    @media (max-width: 1000px) {
        margin: 0 5rem;
    }

    .title {
        margin: 0;
        font-size: 1.5rem;
        color: black;
        font-family: 'Roboto';
        font-weight: 700;
        line-height: normal;
        letter-spacing: normal;
    }

    .subtitle {
        margin: 0.5rem 0;
        // margin-bottom: 1rem;
        font-size: 1rem;
        color: black;
        font-family: 'Roboto';
    }

    .uploadBox {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
        border-radius: 1rem;
        display: flex;
        // background-color: #f5f4f4;
        background-color: white;
        height: 35rem;
        margin-top: 0.5rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        * {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .loading {
            margin-top: 3rem;
            font-size: 1.5rem;
            font-weight: bold;
            z-index: 1;
            color: darkorange;
        }
    }
}
