@import '../../theming//theme.module.scss';

.container {
    width: 100%;

    position: relative;
    width: 100%;
    display: flex;
    max-height: 13rem;
    flex-direction: column;
    margin-top: 3rem;

    & .redirectText {
        margin: 0;
        margin-bottom: 1rem;
    }

    & .button {
        background-color: $primaryColor;
        max-width: 18rem;
        border-radius: 3px;
        display: flex;
        cursor: pointer;
        justify-content: center;

        &:hover {
            background-color: darken($color: $primaryColor, $amount: 10);
        }

        &:active {
            background-color: darken($color: $primaryColor, $amount: 20);
        }

        p.buttonTitle {
            color: white;
            margin: 0.5rem 0;
            font-weight: bold;
            letter-spacing: normal;
            font-size: 1rem;
            text-align: center;
            font-family: 'Roboto';
        }
    }
}
