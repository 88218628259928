@import '../../theming//theme.module.scss';

.spinner {
    width: 8rem;
    animation-delay: 0.5s;
    z-index: 999;
    animation: rotate 3s infinite linear;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
