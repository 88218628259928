@import '../../theming//theme.module.scss';

.container {
    width: 100%;

    .infoBar {
        display: flex;
        flex-direction: row;
        justify-content: right;
        background-color: $primaryColor;
        height: 3rem;
        align-items: center;

        hr {
            border: 0;
            background-color: white;
            width: 2px;
            height: 20px;
            margin: 0 2rem;
        }

        .infoItem {
            margin: 0;
            color: white;

            &:nth-of-type(2) {
                margin-right: 1rem;
            }

            & a {
                text-decoration: none;
                color: white;

                &:hover {
                    font-weight: bold;
                }
            }
        }
    }
}
