.carousel .control-next.control-arrow:before {
    border-top: 24px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 40px solid black;
}

.carousel .control-prev.control-arrow:before {
    border-top: 24px solid transparent;
    border-bottom: 24px solid transparent;
    border-right: 40px solid black;
}

.carousel .control-dots .dot {
    background: darkorange;
}
