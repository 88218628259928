@import '../../theming//theme.module.scss';

.container {
    width: 100%;

    .top {
        display: flex;
        justify-content: space-between;
        background-color: white;
        height: 3rem;
        width: 100%;
        align-items: center;

        .language {
            margin-right: 1rem;
            label {
                color: white;
                font-size: 0.875rem;
            }
            select {
                // background: #404245;
                border: none;
                padding: 0.25rem;
                color: black;
                word-spacing: 0.25rem;
                font-size: 14px;
            }
        }

        .logoBar {
            // background-color: #404245;
            height: 3rem;
            display: flex;
            align-items: center;
            cursor: pointer;

            .logo {
                margin-left: 1rem;
                height: 90%;
            }
        }
    }

    .stepBar {
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: $primaryColor;
        height: 3rem;
        align-items: center;

        hr {
            width: 1rem;
            height: 2px;
            border: 0;
            margin: 0 2rem;
            background-color: white;
        }

        .step {
            margin: 0;
            color: white;
            opacity: 0.7;
            display: flex;
            align-items: center;

            img {
                width: 2.25rem;
                margin-right: 0.5rem;
            }

            &:first-of-type {
                cursor: pointer;
            }

            &.active {
                font-weight: bold;
                opacity: 1;
                font-size: 1.1rem;
            }
        }
    }
}
